<template>
    <div :class="{ mad: this.mad }" class="center wrapper" @mouseover="startBreak" @mouseleave="startMad">
        <span alt="talking face">{{ output }}</span>
    </div>
</template>

<script>
  export default {
    name: 'Face',
    props: {
      isLinksHover: Boolean,
    },
    data() {
      return {
        currentAnim: 0,
        currentFrame: 0,
        break: false,
        mad: false,
        output: "._.",
        animTimeout: null,
        animId: null,
        rndId: null,
        madAnimFrames: [
          'ò˳ó',
          'ò_ó',
          'ò˳ó',
          'ò˳ó',
          'ò_ó',
          'ò˳ó',
          'ò_ó',
          'ò˳ó',
          '-_-',
          'ò_ó',
          'ò_ó',
          'ò_ó',
          'ò_ó',
          'ò_ó',
          'ò_ó',
        ],
        linkHoverOutput: '°-° ',
        frames: [
          [
            'o_o',
            'o_o',
            'o_o',
            'o_o',
            'o_o',
            'o_o',
            'o_o',
            'o_o',
            'o_o',
            '-_-',
          ], 
          [
            'o˳o',
            'o_o',
            'o.o',
            'o_o',
            'o˳o',
            'o˳o',
            'o.o',
            'o_o',
            'o˳o',
            '-_-',
          ],
          [
            'O_O',
            'O_O',
            'O_O',
            'O_O',
            '-_-',
            'O_O',
            '-_-',
            'O_O',
            '-_-',
            'O_O',
            'O_O',
            'O_O',
            'O_O',
            'O_O',
            'O_O',
            'O_-',
            'O_O',
          ], 
          [
            '°-°',
            '._.',
          ],
          [
            '.˳.',
            '._.',
            '.˳.',
            '._.',
            '._.',
            '.˳.',
            '.˳.',
            '._.',
            '._.',
          ],
          [
            '°_°',
            '°˳°',
            '°˳°',
            '°_°',
            '°_°',
            '°˳°',
            '°_°',
            '°˳°',
            '-_-',
          ]
        ]
      }
    },
    methods: {
      animate() {
        if(this.mad) {
          if(this.currentFrame >= (this.madAnimFrames.length - 1)) {
            this.mad = false
          }
          this.output = this.madAnimFrames[this.currentFrame]
          this.currentFrame = this.currentFrame + 1
        } else {
          if(this.isLinksHover) {
            this.output = this.linkHoverOutput
          } else if(!this.break) {
            if(this.currentFrame >= this.frames[this.currentAnim].length) {
              this.currentFrame = 0
            }
            this.output = this.frames[this.currentAnim][this.currentFrame]
            this.currentFrame = (this.currentFrame + 1) % this.frames[this.currentAnim].length
          }
        }
      },
      changeAnimationId() {
        this.currentAnim = Math.floor(Math.random() * this.frames.length)
      },
      startBreak() {
        if(!this.break && !this.mad && !this.isLinksHover) {
          this.break = true
          this.output = 'Ͼ_Ͽ'
        }
      },
      startMad() {
        this.mad = true
        this.break = false
        this.currentFrame = 0
      },
      startAnimation() {
        this.animId = setInterval(this.animate, 200)
        this.rndId = setInterval(this.changeAnimationId, 7000)
      }
    },
    mounted: function(){
      this.startAnimation()
    },
    beforeDestroy: function() {
      clearInterval(this.animId)
      clearInterval(this.rndId)
    }
  }
</script>
  
<style>
  .wrapper {
    padding: 10px 20px;
    border-radius: 40px;
    min-width: 60px;
    text-align: center;
    display: inline-block;
    border: 0.3em solid #333;
    background: #fff;
  }
  .wrapper.mad {
    background: #ffefef;
  }
  .wrapper span {
    font-family: monospace, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bold;
    font-size: 2em;
    color: #333;
    white-space: pre;
  }
  [color-scheme= 'dark'] .wrapper span {
    color: #111;
  }
</style>
  